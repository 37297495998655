import Vue from 'vue'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_FORMALIZACION+'/v2');

export default {
  obtener_solicitudes() {
    return http.get(`/solicitudes`);
  }

  ,obtener_solicitud(id) {
    return http.get(`/solicitudes/${id}`);
  }

  ,editar_solicitud(id, payload) {
    return http.put(`/solicitudes/${id}`, payload);
  }

  ,avanzar_etapa(id, tipo_usuario) {
    if (typeof tipo_usuario == 'undefined')
      return http.put(`/solicitudes/${id}/avanzar_etapa`);
    else
      return http.put(`/solicitudes/${id}/avanzar_etapa?tipo_usuario=${encodeURI(tipo_usuario)}`);
  }

  ,retroceder_etapa(id, payload, tipo_usuario) {
    if (typeof tipo_usuario == 'undefined')
      return http.put(`/solicitudes/${id}/retroceder_etapa`, payload);
    else
      return http.put(`/solicitudes/${id}/retroceder_etapa?tipo_usuario=${encodeURI(tipo_usuario)}`, payload);
  }

  ,definir_oficina(id, oficina) {
    return http.put(`/solicitudes/${id}/oficina/${oficina}`);
  }

  ,cambiar_estatus(id, payload) {
    return http.put(`/solicitudes/${id}/estatus`, payload);
  }

  ,generar_cita(id, payload) {
    return http.post(`/solicitudes/${id}/cita`, payload);
  }

  ,actualizar_cita(id, payload) {
    return http.put(`/solicitudes/${id}/cita`, payload);
  }

  ,cancelar_cita(id, idEvent) {
    return http.delete(`/solicitudes/${id}/cita/${idEvent}`,);
  }

  ,agregar_observacion(id, payload) {
    return http.post(`/solicitudes/${id}/observaciones`, payload);
  }

  ,reasignar_solicitud(id, payload) {
    return http.put(`/solicitudes/${id}/usuarios/reasignar`, payload);
  }

  ,notificacion_documentos_invalidos(id, payload) {
    return http.post(`/solicitudes/${id}/notificaciones/documento-invalido`, payload);
  }

  //Documentos
  ,cargar_documentos(id, payload) {
    return http.post(`/solicitudes/${id}/documentos`, payload);
  }

  //Inmuebles
  ,crear_inmueble(id, payload) {
    return http.post(`/solicitudes/${id}/inmuebles`, payload);
  }

  ,editar_inmueble(id, inmueble, payload) {
    return http.put(`/solicitudes/${id}/inmuebles/${inmueble}`, payload);
  }

  ,eliminar_inmueble(id, inmueble) {
    return http.delete(`/solicitudes/${id}/inmuebles/{inmueble}`);
  }

  //Incidencias
  ,incidencias_sincronizar(id, payload) {
    return http.post(`/solicitudes/${id}/incidencias`, payload);
  }

  ,incidencias_editar(id, payload) {
    return http.put(`/solicitudes/${id}/incidencias`, payload);
  }

  //Incidencias -> Evidencias
  ,incidencias_evidencias(id, incidencia, payload) {
    return http.post(`/solicitudes/${id}/incidencias/${incidencia}/evidencias`, payload);
  }

  ,incidencias_evidencias_eliminar(id, incidencia, evidencia) {
    return http.delete(`/solicitudes/${id}/incidencias/${incidencia}/evidencias/${evidencia}`);
  }

  ,incidencias_evidencias_descargar(id, incidencia, evidencia) {
    return http.get(`/solicitudes/${id}/incidencias/${incidencia}/evidencias/${evidencia}`, {responseType: 'blob'});
  }

  //Revisiones
  ,obtener_revisiones(id) {
    return http.get(`/solicitudes/${id}/revisiones`);
  }

  ,obtener_revision(id, revision) {
    return http.get(`/solicitudes/${id}/revisiones/${revision}`);
  }

  ,crear_revision(id, payload) {
    return http.post(`/solicitudes/${id}/revisiones`, payload);
  }

  ,editar_revision(id, revision, payload) {
    return http.put(`/solicitudes/${id}/revisiones/${revision}`, payload);
  }

  ,eliminar_revision(id, revision) {
    return http.delete(`/solicitudes/${id}/revisiones/${revision}`);
  }

  ,actualizar_etapa_sistema(id, payload){
    return http.post(`/solicitudes/${id}/actualizar_etapa_sistema`, payload);
  }
  ,retroceder_etapa_sistema(id, payload){
    return http.post(`/solicitudes/${id}/retroceder_etapa_sistema`, payload);
  }
}