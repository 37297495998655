import axios from '@/plugins/axiosV2'

const http = axios(process.env.VUE_APP_API_VALIJA);

export default {
  listar_documentos(id) {
    return http.get(`/solicitudes/${id}`);
  }
  
  ,cargar_documentos(payload) {
    return http.post(`/solicitudes/documentos`, payload);
  }

  ,obtener_catalogo(codigo) {
    return http.get(`/catalogos/buscar/${encodeURIComponent(codigo)}`);
  }

  ,descargar_documento(id, documento) {
    return http.get(`/solicitudes/${id}/documentos/${documento}`, {responseType: 'blob'});
  }

  ,invalidar_documento(id, documento) {
    return http.put(`/solicitudes/${id}/documentos/${documento}/bloquear`);
  }
}