<template>
  <div class="row">
    <div class="col-sm-10 offset-sm-1">
      <div class="row">
        <h1 class="col-sm-12">Carga de CLG</h1>
      </div>
      <div class="row mb-4">
        <div class="col-sm-12">
          <div class="row mb-4">
            <div class="col-sm-12">Agregar el CLG a la solicitud</div>
          </div>
          <div class="row">
            <label for="" class="col-form-label col-sm-2">CLG</label>
            <div class="col-sm-10">
              <div class="input-group">
                <div class="form-control" ref="seleccionar_archivo_nombre"></div>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" @click="seleccionar_archivo_clg">Seleccionar documento</button>
                </div>
              </div>
              <input class="seleccionar_archivo" type="file" name="seleccionar_archivo" id="seleccionar_archivo" ref="seleccionar_archivo" @change="archivo_seleccionado">
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <label for="caducidad_clg" class="col-form-label col-sm-2">Vencimiento del CLG</label>
        <div class="col-sm-10"><input v-model="caducidad_clg" type="date" name="caducidad_clg" id="caducidad_clg" class="form-control"></div>
      </div>

      <div class="row form-group mb-4">
        <label for="fecha_expedicion_clg" class="col-form-label col-sm-2">Fecha de expedición del CLG</label>
        <div class="col-sm-10"><input type="date" v-model="fecha_expedicion_clg" name="fecha_expedicion_clg" id="fecha_expedicion_clg" class="form-control" ></div>
      </div>

      <div class="row mb-4">
        <label for="" class="col-form-label col-sm-2">Se encontraron incidencias</label>
        <div class="col-sm-10">
          <BpbRadio v-model="existe_incidencias" :other="{display: 'inline'}" value="1" name="incidencias" label="Sí" :disabled="incidencias.length > 0"/>
          <BpbRadio v-model="existe_incidencias" :other="{display: 'inline'}"  value="0" name="incidencias" label="No" :disabled="incidencias.length > 0"/>
        </div>
      </div>

      <template v-if="existe_incidencias==1">
      <div class="row mb-2">
        <div class="col-sm-12 text-right"><button class="btn btn-outline-secondary" @click="modal_incidencia_nueva=true">Agregar incidencia</button></div>
      </div>

      <div class="row mb-4">
        <div class="col-sm-12">
          <table class="lista_incidencias">
            <thead>
              <tr>
                <th>Tipo de Incidencia</th>
                <th>Descripción</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(incidencia, index) in incidencias" :key="incidencia.id">
                <td>{{ obtener_nombre(incidencia.subtipo) }}</td>
                <td>{{ incidencia.descripcion }}</td>
                <td>
                  <img class="mr-2" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar incidencia" title="Editar incidencia" @click="editar_incidencia(index, incidencia)" />
                  <img src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar incidencia" title="Elimitar incidencia" @click="eliminar_incidencia(index)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </template>

      <div class="row">
        <div class="col-sm-12 text-right">
          <button class="btn btn-warning mr-2" @click="$emit('retroceder')">Retroceder etapa</button>
          <button v-if="existe_incidencias==1" class="btn btn-info mr-2" @click="guardar_cambios">Guardar cambios</button>
          <button class="btn btn-success" @click="cargar_clg">Cargar CLG</button>
        </div>
      </div>
    </div>

    <Incidencia v-if="modal_incidencia_nueva" :solicitud="solicitud" :incidencia="incidencia" :catalogo="catalogo" @agregar="agregar_incidencia" @close="cancelar_incidencia" />
  </div>
</template>

<script type="text/javascript">
  import BpbRadio from '@/components/Form/Radio'
  import Incidencia from '@/apps/formalizacion/Componentes/Incidencia'

  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'
  import apiCatalogo from '@/apps/formalizacion/api/v2/catalogos'
  import apiValija from '@/apps/formalizacion/api/v2/valija'

  export default {
    components: {
      BpbRadio, Incidencia
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
            ,solicitud: null
          }
        }
      }
    }
    ,data() {
      return {
        existe_incidencias: 0
        ,incidencias: []
        ,incidencias_eliminadas: []
        ,catalogo: {
          opciones: []
        }
        ,modal_incidencia_nueva: false
        ,incidencia: {
          subtipo: null
          ,descripcion: null
        }
        ,caducidad_clg: null
        ,fecha_expedicion_clg: null
      }
    }
    ,mounted() {
      this.obtener_catalogo();

      if (this.solicitud.caducidad_clg_at)
        this.caducidad_clg = this.$moment(this.solicitud.caducidad_clg_at).format('YYYY-MM-DD');

      if (this.solicitud.fecha_expedicion_clg)
        this.fecha_expedicion_clg = this.$moment(this.solicitud.fecha_expedicion_clg).format('YYYY-MM-DD');

      this.cargarIncidenciasSolicitud()
    }
    ,methods: {
      async obtener_catalogo() {
        this.catalogo = await this.$helper.cache_store('formalizacion.catalogos.incidencias_clg',async () => {
          return (await apiCatalogo.buscar_catalogo('incidencias_clg')).data
        });
      }
      ,seleccionar_archivo_clg() {
        this.$refs.seleccionar_archivo.click();
      }
      ,archivo_seleccionado() {
        if (this.$refs.seleccionar_archivo.files.length > 0)
          this.$refs.seleccionar_archivo_nombre.innerHTML = this.$refs.seleccionar_archivo.files[0].name;
        else
          this.$refs.seleccionar_archivo_nombre.innerHTML = null;
      }
      ,async cargar_clg() {
        try {

          if (!this.validaciones_pre_carga_clg()) {
            return;
          }
          let formdata = new FormData();
          formdata.append('solicitud_id', this.solicitud.solicitud);
          formdata.append('usuario', this.$auth.getUser().nombre);
          formdata.append('archivos[][archivo]', this.$refs.seleccionar_archivo.files[0]);
          formdata.append('archivos[][tipo]', 'clg');

          let res = (await apiValija.cargar_documentos(formdata)).data;
          this.$log.info('res',res);

          res = (await apiSolicitud.editar_solicitud(this.solicitud.id, {caducidad_clg: this.caducidad_clg, fecha_expedicion_clg: this.fecha_expedicion_clg})).data;
          this.$log.info('res',res)

          this.$emit('avanzar');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,obtener_nombre(subtipo) {
        let nombre;

        this.catalogo.opciones.forEach(opcion => {
          if (opcion.valor == subtipo)
            nombre = opcion.nombre;
        })

        return nombre;
      }
      ,cancelar_incidencia() {
        this.incidencia = {
          id: null
          ,subtipo: null
          ,descripcion: null
        }
        this.modal_incidencia_nueva = false;
      }
      ,agregar_incidencia(incidencia) {
        if (typeof incidencia.index == 'undefined')
          this.incidencias.push(incidencia);
        else {
          for(let i=0; i<this.incidencias.length; i++) {
            if (i == incidencia.index) {
              this.incidencias[i] = incidencia;
            }
          }
        }

        this.cancelar_incidencia();
      }
      ,async guardar_cambios() {
        try {
          let payload = {
            incidencias_tipo: 'dictamen_clg'
            ,incidencias: this.nuevas_incidencias
            ,incidencias_eliminar: []
          }


          if (this.incidencias_eliminadas.length > 0)
            this.incidencias_eliminadas.forEach(incidencia => {
              payload.incidencias_eliminar.push(incidencia);
            })

          if (payload.incidencias.length == 0 && payload.incidencias_eliminar.length == 0)
            return this.$helper.showMessage('Error','No hay cambios para guardar','error','alert');

          let res = (await apiSolicitud.incidencias_sincronizar(this.solicitud.id, payload)).data;
          this.$log.info('res', res);

          if (res.errores.length > 0){
            let error = 'Se encontraron los siguientes errores: <ul>'
            res.errores.forEach(tmp_error => {
              error = error + '<li>'+tmp_error+'</li>';
            })
            error = error + '</ul>';
            return this.$helper.showMessage('Error',error, 'error','alert');
          }

          this.$helper.showMessage('Echo!','Se sincronizaron las incidencias','success','alert');
          if (payload.incidencias_eliminar.length > 0){
            this.incidencias_eliminadas = [];
          }

          this.$emit('update')
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,editar_incidencia(index, incidencia) {
        incidencia.index = index;
        this.incidencia = incidencia;
        this.modal_incidencia_nueva = true;
      }
      ,eliminar_incidencia(index) {
        let incidencias = [];
        for(let i=0; i<this.incidencias.length; i++) {
          if (i != index)
            incidencias.push(this.incidencias[i]);
          else
            this.incidencias_eliminadas.push(this.incidencias[i]);
        }

        this.incidencias = incidencias;
      }
      ,validaciones_pre_carga_clg() {
        let success = true;
        if (this.$refs.seleccionar_archivo.files.length === 0) {
          this.$helper.showMessage('Error', 'Debes seleccionar un archivo', 'error', 'alert');
          success = false;
        }

        if (!this.caducidad_clg) {
          this.$helper.showMessage('Error', 'Debes definir la fecha de caducidad del CLG', 'error', 'alert');
          success = false;
        }

        if (!this.fecha_expedicion_clg) {
          this.$helper.showMessage('Error', 'Debes definir la fecha de expedición de CLG', 'error', 'alert');
          success = false;
        }

        const nuevas_incidencias = this.nuevas_incidencias;
        console.log('nuevas incidencias', nuevas_incidencias)
        if (nuevas_incidencias.length > 0 || this.incidencias_eliminadas.length > 0 && success) {
          this.guardar_cambios();
        }

        if (this.existe_incidencias == 1) {
          if (this.incidencias.length === 0) {
            this.$helper.showMessage('Error', 'Si seleccionó que si se encontraron incidencias, debe agregar al menos una incidencia para poder avanzar', 'error', 'alert');
            success = false;
          }
        }

        return success;
      }
      ,cargarIncidenciasSolicitud(){
        let incidencias = this.incidencias_validas;
        if (incidencias.length > 0) {
          this.existe_incidencias = 1;
          this.incidencias = incidencias;
        }
      }
    }
    ,computed: {
      incidencias_validas() {
        let incidencias = [];

        this.solicitud.incidencias.forEach(incidencia => {
          if (incidencia.tipo == 'dictamen_clg')
            incidencias.push(incidencia)
        })

        return incidencias;
      }
      ,nuevas_incidencias(){
          let nuevas_incidencias = [];
          this.incidencias.forEach(incidencia => {
            if (!incidencia.id)
              nuevas_incidencias.push(incidencia);
            else if(incidencia.index)
              nuevas_incidencias.push(incidencia);
          })
          return nuevas_incidencias;
        }
    }
    ,watch: {
      solicitud: {
        handler() {
          this.cargarIncidenciasSolicitud();
        }
        , deep: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .lista_incidencias {
    width: 100%;

    thead {
      tr {
        th {
          background-color: #4C4C4C;
          color: #fff;
          padding: 5px 10px;
        }
      }
    }

    tbody {
      tr {
        // border-bottom: solid 1px #E6E6E6;

        td {
          padding: 5px 10px;
          img {
            width: 20px;
          }
        }

        td:nth-child(3) {
          text-align: center;
        }
      }

      tr:nth-child(2n+0) {
        background-color: #E6E6E6;
      }
    }
  }
</style>