<template>
	<div :class="'bpb-radio-component'+radio_display+radio_type+(custom_class ? ' '+custom_class : '')">
		<input :ref="ref" type="radio" :name="name" :id="id" :value="value" class="bpb-radio-input" :data-radioid="ref" :checked="checked" @change="seleccionar" :disabled="disabled">
		<div class="bpb-radio">
			<span class="bpb-radio-border"></span>
			<span class="bpb-radio-circle"></span>
		</div>
		<label v-if="label" :class="'bpb-radio-label'+radio_label" :for="id">{{ label }}</label>
	</div>
</template>

<script type="text/javascript">
	export default {
		props: {
			value: null
			,model: null
			,id: null
			,name: null
			,label: null
			,type: null
			,checked: false
			,readonly: false
			,disabled: false
			,custom_class: null
			,other: {
				type: Object
				,default: () => ({})
			}
		}
		,model: {
			prop: 'model'
			,event: 'checked'
		}
		,data: function() {
			return {
				input: null
				,ref: null
			}
		}
		,created: function() {
			if (!this.ref)
				this.ref = 'bpbradio'+this.$helper.strRand(8);
		}
		,mounted: function() {
			const dataset = this.$el.dataset;
			const input = this.$refs[this.ref];
			const datasetKeys = Object.keys(dataset);
			// this.$log.info('datasetKeys',datasetKeys)
			
			let radio = document.querySelector('input[type=radio][data-radioid='+this.ref+']');
			if (radio.value == this.model && (!this.readonly && !this.disabled))
				radio.click();

			if (datasetKeys.length > 0)
				datasetKeys.forEach(prop => {
					if (prop.indexOf('v_') > -1)
						input.dataset[prop] = dataset[prop];
				});
		}
		,methods: {
			seleccionar: function(e) {
				// if (e.target.checked)
					this.$emit('checked', e.target.value);
			}
		}
		,computed: {
			radio_display: function() {
				return this.other.display && this.other.display == 'inline' ? ' bpb-radio-component-inline' : '';
			}
			,radio_type: function() {
				const types = ['primary','success','warning','danger','info','secondary','dark','light'];

				if (types.indexOf(this.type) > -1)
					return ' pbp-radio-type-'+this.type;

				return ' pbp-radio-type-primary';
			}
			,radio_label: function() {
				return this.disabled || this.readonly ? ' label-disabled' : '';
			}
		}
		,watch: {
			model: {
				handler: function(val,oldVal) {
					this.$log.info('model cambio!',this.model);
					let input = this.$refs[this.ref];
					if (input.value == val && (!this.readonly && !this.disabled))
						input.click();
				}
				,deep: true
			}
			,readonly: function(val) {
				this.disabled = val;
			}
		}
	}
</script>

<style lang="scss">
	.bpb-radio-component {
		align-items: center;
		justify-content: flex-start !important;
		position: relative;

		input.bpb-radio-input {
			width: 18px;
			height: 18px;
			position: absolute;
			top: 1px;
			opacity: 0;
			z-index:200;
		}

		.bpb-radio {
			width: 18px;
			height: 18px;
			position: relative;
			flex-shrink: 0;
			cursor: default;
			display: inline-block;			

			span {
				border-radius: 50%;
				margin: 0px !important;
				transform: translate(-50%, -50%);
				position: absolute;
				top: 50%;
				left: 50%;
			}

			span.bpb-radio-border {
				width: 100%;
				height: 100%;
				background-color: #CCCCCC;
			}

			span.bpb-radio-circle {
				width: 75%;
				height: 75%;
				background-color: #fff;
				transform: translate(-53%, -51%);
			}
		}

		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-border {
			background-color: #0E608D;
		}

		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-circle {
			background-color: #0781cd;
		}

		label.bpb-radio-label {
			margin-left: 5px;
			display: inline-block;
			vertical-align: middle;
		}

		label.label-disabled {
			color: #B3B3B3;
		}
	}

	.bpb-radio-component-inline {
		display: inline-block;
		margin-right: 10px;
	}

	.pbp-radio-type-primary {
		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-border {
			background-color: #0E608D;
		}

		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-circle {
			background-color: #0781cd;
		}
	}

	.pbp-radio-type-success {
		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-border {
			background-color: #238A53;
		}

		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-circle {
			background-color: #55d775;
		}
	}

	.pbp-radio-type-warning {
		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-border {
			background-color: #CC6817;
		}

		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-circle {
			background-color: #f99f43;
		}
	}

	.pbp-radio-type-danger {
		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-border {
			background-color: #A91016;
		}

		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-circle {
			background-color: #ea5455;
		}
	}

	.pbp-radio-type-info {
		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-border {
			background-color: #357880;
		}

		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-circle{
			background-color: #17a2b8;
		}
	}

	.pbp-radio-type-secondary {
		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-border {
			background-color: #3B3B3B;
		}

		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-circle {
			background-color: #6c757d;
		}
	}

	.pbp-radio-type-dark {
		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-border {
			background-color: #000000;
		}

		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-circle {
			background-color: #343a40;
		}
	}

	.pbp-radio-type-light {
		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-border {
			background-color: #000000;
		}

		input.bpb-radio-input:checked+.bpb-radio .bpb-radio-circle {
			background-color: #fff;
		}
	}

</style>